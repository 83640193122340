import React from "react";
import PageContainer from "../components/pageContainer";

const NotFoundPage = () => {
  return (
    <PageContainer title="Loading...">
      <h3>Loading...</h3>
    </PageContainer>
  );
};

export default NotFoundPage;
